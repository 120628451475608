import React from "react";
import { graphql } from "gatsby";
import Container from "../components/global/container";
import Layout from "../components/global/layout";
// import MultiStepForm from '../components/multistep-form/multistep-form'
import ContactForm from "../components/contact-form/contact-form";

class ContactPage extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout
                location={this.props.location}
                title={`Early Access | ${siteTitle}`}
            >
                <section className="full-screen background-black">
                    <Container>
                        <div className="row">
                            <ContactForm />
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default ContactPage;

export const ContactQuery = graphql`
    query ContactQuery {
        site {
            siteMetadata {
                title
                brandName
            }
        }
    }
`;
