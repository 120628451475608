import React from "react";
import { Formik } from "formik";
import * as styles from "./contact-form.module.scss";

const ContactForm = () => {
    return (
        <div className="col-12 pt-120 pb-60">
            <div className="card background-primary p-40 p-md-60 position-relative overflow-hidden max-width-800 mx-auto">
                <div className={styles.formPattern}>
                    <img
                        src="../../pattern-light.svg"
                        alt="Background Pattern"
                    />
                </div>
                <h2 className="text-white line-height-2 mb-40 mt-20 mt-md-40 font-size-lg font-weight-bold max-width-600 mx-auto text-center position-relative z-index-2">
                    Apply For Early Access
                </h2>
                <div className=" mx-auto mb-20 mb-md-40 position-relative z-index-2">
                    <Formik
                        initialValues={{ email: "", name: "", tel: "" }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Required Field";
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = "Please enter a valid address";
                            }
                            if (!values.name) {
                                errors.name = "Required Field";
                            } else if (values.name.length < 3) {
                                errors.name = "Please enter your full name";
                            }
                            return errors;
                        }}
                        onSubmit={(values, actions) => {
                            document.getElementById("hForm").innerHTML =
                                "<p class='thank-you-message'>Thank you. We will be in touch soon!</p>";
                            fetch("/.netlify/functions/mail", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(values),
                            }).then((res) => {
                                console.log(res);
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form
                                id="hForm"
                                className={styles.contactForm}
                                onSubmit={handleSubmit}
                            >
                                <div className={styles.fieldsContainer}>
                                    <label>
                                        <input
                                            type="text"
                                            name="name"
                                            aria-label="Full Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        <span className={styles.labelText}>
                                            Full Name
                                        </span>
                                        <span className={styles.errorText}>
                                            {errors.name &&
                                                touched.name &&
                                                errors.name}
                                        </span>
                                    </label>
                                    <label>
                                        <input
                                            type="email"
                                            name="email"
                                            aria-label="Email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        <span className={styles.labelText}>
                                            Email
                                        </span>
                                        <span className={styles.errorText}>
                                            {errors.email &&
                                                touched.email &&
                                                errors.email}
                                        </span>
                                    </label>
                                    <button
                                        className="font-weight-light"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Submit &nbsp; &#8674;
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
